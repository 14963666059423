<template>
    <Form @submit="handleSubmit" v-slot="{ errors }">
        <div class="modal" v-if="modelValue" :class="{ active : modelValue }">
            <div class="modal_container">
                <div class="modal_header border-bottom">
                    <h1>Zapier Integration</h1>
                    <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
                </div>
                <div class="modal_body">
                    <div class="setting_wpr">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Invitation URL</label>
                                <div class="field_wpr has_suffix">
                                    <input type="text" value="https://zapier.com/developer/public-invite/69414/0acdd28f6d18ed7d48ea69765a411e9a/" >
                                    <span class="suffix pointer" @click="copyInvitationURL()">Copy</span>
                                </div>

                                <div class="custDomain_info mt-2">
                                    <p class="m-0"><b>Note:</b> If it is your first time to connect to zapier copy and paste the invitation url in to a new tab.</p>
                                </div>
                            </div>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">
                                    Select Tags
                                    <a class="option" @click="createTags = !createTags">Create a Tag</a>
                                </label>
                                <div class="field_wpr" :class="{ 'has-error': errors.tag }">
                                    <Field autocomplete="off" name="tag" v-model="form.tags">
                                        <multiselect
                                            v-model="form.tags"
                                            mode="tags"
                                            label="name"
                                            value-prop="id"
                                            :searchable="true"
                                            :group-select="true"
                                            :options="tagsGroup"
                                            :groups="true"
                                            group-label="type"
                                            group-options="tags"
                                            :group-hide-empty="true"
                                            placeholder="Select Tags"
                                        >
                                            <template v-slot:grouplabel="{ group }">
                                                <span :class="group.type.replace(' ', '-').toLowerCase()">{{ group.type }}</span>
                                            </template>
                                            <template v-slot:option="{ option }">
                                                <span>{{ option.name }}</span>
                                            </template>
                                            <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                                <div class="multiselect-tag" :class="option.type.replace(' ', '-').toLowerCase()">
                                                  {{ option.name }}
                                                  <span
                                                    v-if="!disabled"
                                                    class="multiselect-tag-remove"
                                                    @mousedown.prevent="handleTagRemove(option, $event)"
                                                  >
                                                      <span class="multiselect-tag-remove-icon"></span>
                                                  </span>
                                                </div>
                                            </template>
                                        </multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="tag" class="text-danger" />
                            </div>
                        </div>
                        <div v-if="createTags" class="form_grp">
                            <Form v-slot="{ errors }" ref="tag-form">
                                <div class="group_item">
                                    <label class="input_label">Create a New Tag</label>
                                    <div class="field_wpr has_suffix" :class="{ 'has-error': errors.name }">
                                        <Field autocomplete="off" type="text" v-model="tagForm.name" name="name" placeholder="Enter Tag Name.." rules="required" />
                                        <span class="suffix pointer" @click="handleTagCreate"><i class="fa fa-spinner fa-spin" v-if="tagLoader"></i> {{ tagLoader ? 'Creating' : 'Create' }}</span>
                                    </div>
                                    <ErrorMessage name="name" class="text-danger" />
                                </div>
                            </Form>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Access Token</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.access_token }">
                                    <Field autocomplete="off" name="access_token" v-model="form.access_token" rules="required" label="access token">
                                        <textarea v-model="form.access_token" name="access_token" cols="30" rows="10"></textarea>
                                    </Field>
                                </div>
                                <ErrorMessage name="access_token" class="text-danger" />
                            </div>
                        </div>
                        <div class="action_wpr">
                            <button class="btn cancel_btn bg-light" type="button" @click="generateAccessToken()"><i class="fa fa-spin fa-spinner" v-if="loader"></i> {{ loader ? 'Generating' : 'Generate Code' }}</button>
                        </div>
                    </div>
                </div>
                <div class="modal_footer">
                    <button type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                    <button class="btn save_btn" @click="copyAccessToken()">Copy</button>
                </div>
            </div>
        </div>
    </Form>
</template>

<script>
    import { copyText } from 'vue3-clipboard'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    import Toastr from '@/utils/Toastr'
    import Helper from '@/utils/Helper'
    import Swal from 'sweetalert2'

    export default {
        name: 'Zapier Integration',

        data () {
            return {
                form: {
                    tags: [],
                    access_token: '',
                },
                loader: false,
                createTags: false,
                tagForm: {
                    name: '',
                    type: 'leads',
                    uniqueId: 'bulk-action',
                },
            };
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
        },

        watch: {
            user: {
                handler () {
                    const vm = this;

                    vm.resetForm();
                },
                deep: true
            },

            modelValue(val) {
                if(val) {
                    const vm = this;

                    vm.createTags    = false;
                    vm.tagForm.name  = '';
                    vm.form          = { tags: [], access_token: '' };

                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },
        },

        computed: mapState({
            user: state => state.authModule.user,
            tagsGroup: state => state.tagModule.tagsGroup,
            tagLoader: state => state.tagModule.tagLoader,
        }),

        methods: {
            ...mapActions({
                getTags: 'tagModule/getTags',
                createTag: 'tagModule/createTag',
                generateZapierAccessToken: 'authModule/generateZapierAccessToken',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    tags: [],
                    access_token: '',
                };
            },

            handleTagCreate () {
                const vm = this;
                const form = vm.$refs['tag-form'];

                form.validate().then(({ valid }) => {
                    if (valid) {
                        vm.tagForm.setFieldError = form.setFieldError;

                        vm.createTag(vm.tagForm).then((result) => {
                            vm.createTags = false;
                            vm.tagForm.name = '';
                            vm.getTags();
                        });
                    }
                });
            },

            generateAccessToken () {
                const vm = this;
                vm.loader = true;

                vm.generateZapierAccessToken(vm.form).then((resp) => {
                    vm.form.access_token = resp.data.access_token;
                    vm.loader = false;
                }).catch((err) => {
                    console.log(err);
                    Toastr.handleServerError(err);
                    vm.loader = false;
                });
            },

            handleSubmit (params, { setFieldError, resetForm }) {
                const vm = this;

                vm.form.setFieldError = setFieldError;
                vm.saveLoader = true;

                vm.saveFitFunnelWebhook(vm.form).then((result) => {
                    if (result) {
                        vm.resetForm();
                        resetForm();
                    }

                    vm.saveLoader = false;
                });
            },

            copyAccessToken () {
                const vm = this;

                copyText(vm.form.access_token, undefined, (error, event) => {
                    if (error) {
                        Toastr.error('Failed to copy access token!');
                    } else {
                        Toastr.success('Access token has been copied successfully!');
                    }
                })
            },

            copyInvitationURL () {
                const vm = this;

                copyText('https://zapier.com/developer/public-invite/69414/0acdd28f6d18ed7d48ea69765a411e9a/', undefined, (error, event) => {
                    if (error) {
                        Toastr.error('Failed to copy code!');
                    } else {
                        Toastr.success('Invitation URL has been copied successfully!');
                    }
                })
            },
        },
    }
</script>
